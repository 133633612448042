
.header {
  background-color: #1B242F;
  width: 100%;
  height: max-content;
  color: #fff;

  border-bottom: 0.2em solid #04C2C9;
}

.top-header-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
}
.top-header-name {
  font-size: x-large;
  white-space: nowrap;
  margin: 0.2em;
}
.header-items-desktop {
  display: none;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row-reverse;
}
.header-items-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 0;
}
.header-item {
  margin: 0.5em 1em;
  text-decoration: none;
  opacity: 1;
  font-weight: 400;
  transition: 200ms;
}
.header-item:hover {
  opacity: 0.5;
}

@media screen and (min-width : 600px) {
  .header-items-desktop {
    display: flex;
  }
  .header-mobile {
    display: none;
  }
  .header-items-mobile {
    display: none;
  }
}