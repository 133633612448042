body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
  background-color: #fff;
  color: #051236;
}

* {
  box-sizing: border-box;
}

.logo {
  height: 2rem;
  margin: 0.1rem;
}
.container {
  background-color: #fff;
  
}
a:link {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}
a #hide {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}
#error {
  color: red;
}
#success {
  color: green;
}