
.footer {
  background-color: #1B242F;
  width: 100%;
  height: fit-content;
  color: white;
  padding-top: 0.2em;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.by-text {
  font-size: 0.8rem;
  margin-bottom: 0.5em;
  margin-top: 0;
}

.footer-link {
  display: inline-flex;
  background-color: #272F39;
  padding: 0.2em;
  border-radius: 4px;
}
.footer-link>img {
  margin: 0;
}
.footer-link-a {
  margin: 0.2em;
}
.footer-links {
  width: 30%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.menu {
  height: 2rem;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
}

